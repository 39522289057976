import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Help from 'images/blogs/blog-5.jpg'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/6-tips-for-digipay-agents`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '6 Tips for Digipay Agents',
  },
  {
    property: 'og:description',
    content: 'Alamin ang mga tips para maging mas mahusay na Digipay Agent!',
  },
  {
    property: 'og:image',
    content: Help,
  },
]

const HelpfulTips = () => (
  <Layout>
    <Helmet
      title="6 Tips for Digipay Agents"
      meta={SITE_META}
      fontWeight="bold"
    >
      <html lang="en" />
    </Helmet>
    <Row flexJustify="center" m="30px">
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3">
        <Image width="100%" mb="sp3" src={Help} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          6 TIPS FOR DIGIPAY AGENTS
        </Heading>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            STRONG INTERNET CONNECTION{' '}
          </Heading>
          <Text lineHeight="1.5">
            Hindi ba successful ang transactions mo o mabagal ang app? Check
            your internet connection para tuloy-tuloy ang kita gamit ang Digipay
            app. Alamin at pumunta kung saang parte ng tindahan ang may malakas
            na signal para maiwasan ang aberya kapag nagpa-process ng
            transanction. Sa ganitong paraan, maiiwasan ang abala sa business mo
            at sa customer.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            ATTENTION TO DETAIL
          </Heading>
          <Text lineHeight="1.5">
            I-double check ang amount, account number, at kung may dobleng
            transaction sa inyong app bago mag-proceed sa gagawing transaction.
            Sa ganitong paraan, maiiwasan ang paghihintay na maibalik ang
            Digipay credits o ang posting ng mga maling transactions. Tandaan na
            hindi na maibabalik ang Digipay credits kapag mali ang transaction
            gamit ang Smart Padala at e-load kaya naman pinapaala na dapat tama
            ang lahat ng detalyeng ilalagay sa app.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            TOP-UP AHEAD OF TIME
          </Heading>
          <Text lineHeight="1.5">
            Maglaan ng sapat na oras para sa top-up process. Iwasang magtop-up
            kapag ubos na ang Digipay credits para hindi matigil ang inyong
            business. Mag-upload ng malinaw at tamang deposit slip gamit ang
            Digipay app. Paalala na tandaan ang mga tamang top-up details at
            requirements na kailangan para sa mas mabilis na funding processing.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REPORTS? REFER TO SALES DATA
          </Heading>
          <Text lineHeight="1.5">
            Kung hindi niyo maalala ang mga successful transactions, ‘wag
            mag-alala dahil maaari kayong pumunta sa Sales Data para ma-view ang
            recent transactions na inyong ginawa. Dito mo makikita ang detalye
            ng mga transactions katulad ng transaction ID, date, pangalan,
            product, rebates, at iba pa.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REFERRAL FEE
          </Heading>
          <Text lineHeight="1.5">
            Hindi lang sa rebates maaaring kumita kapag naging Digipat Agent
            kayo. Makakakuha ng referral fee ang current agent na sino mang
            magre-refer ng new agent. Dahil dito, may dagdag na source of income
            kayo bilang Digipay Agent.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            BE UPDATED
          </Heading>
          <Text lineHeight="1.5">
            Gusto mo bang malaman ang latest updates ng Digipay? Siguraduhing
            active ka sa pag-check ng Digipay Agent Facebook group at email.
            ‘Wag ding kalimutang basahin ang push notifications na sinesend ng
            Digipay team. Dito mo malalaman ang funding schedule, promos, at iba
            pang happenings sa Digipay.
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)
export default HelpfulTips
